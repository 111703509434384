import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [{
    path: '/',
    component: () =>
        import ( /* webpackChunkName: "stock" */ '../src/views/index.vue'),
    redirect: '/home',
    children: [{
        path: '/home',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/home.vue'),
    }, {
        path: '/momentum',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/momentum.vue'),
    }, {
        path: '/industryHit',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/industryHit.vue'),
    },{
        path: '/auctionTestChoose',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/auctionTestChoose.vue'),
    }, {
        path: '/globalHit',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/globalHit.vue'),
    }, {
        path: '/makeMoney',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/makeMoney.vue'),
    }, {
        path: '/theory',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/theory.vue'),
    },{
        path: '/thsFocusTop',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/thsFocusTop.vue'),
    },{
        path: '/blockLinkage',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/blockLinkage.vue'),
    },  {
        path: '/industryAmount',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/industryAmount.vue'),
    },{
        path: '/inOutDisk',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/inOutDisk.vue'),
    },{
        path: '/rzye',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/rzye.vue'),
    },{
        path: '/jgzy',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/jgzy.vue'),
    },{
        path: '/rzyeDetail',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/rzyeDetail.vue'),
    },{
        path: '/stockPE',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/stockPE.vue'),
    },{
        path: '/industryPE',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/industryPE.vue'),
    },{
        path: '/realTimeStock',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/realTimeStock.vue'),
    },{
        path: '/industryPriceLimit',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/industryPriceLimit.vue'),
    },{
        path: '/myFollow',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/myFollow.vue'),
    },{
        path: '/followMsg',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/followMsg.vue'),
    },{
        path: '/historyCallAuction',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/historyCallAuction.vue'),
    },{
        path: '/theDayCallAuction',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/theDayCallAuction.vue'),
    },{
        path: '/globalEmotion',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/globalEmotion.vue'),
    },{
        path: '/theDayIndustryCallAuction',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/theDayIndustryCallAuction.vue'),
    },{
        path: '/readTimeIndustry',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/readTimeIndustry.vue'),
    },{
        path: '/myFollowMinLine',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/myFollowMinLine.vue'),
    },{
        path: '/myExpect',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/myExpect.vue'),
    },{
        path: '/myAuct',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/myAuct.vue'),
    },{
        path: '/industryRps',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/industryRps.vue'),
    },{
        path: '/stockDetails',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/stockDetails.vue'),
    },{
        path: '/priceList',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/priceList.vue'),
    },{
        path: '/tradeLimit',
        component: () =>
            import ( /* webpackChunkName: "stock" */ './views/tradeLimit.vue'),
    }]

}, ]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
export default router;