import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import router from './router';
import App from './App';
import 'ant-design-vue/dist/antd.less';

import axios from "axios";
import * as echarts from 'echarts'

const app = createApp(App);
app.use(router)
app.use(Antd).mount('#app');
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$echarts = echarts

// 请求拦截器
// 在发送请求前 操作
app.config.globalProperties.$axios.interceptors.request.use((config) => {
    let token = localStorage.getItem('accessToken');
    if (token != null && token !== '') {
        // 获取 并 在请求头设置 token
        config.headers['accessToken'] = token
    }
    return config
}, (error) => {
    return Promise.reject(error)
})


// 响应拦截器
// 对响应数据的操作
app.config.globalProperties.$axios.interceptors.response.use((response) => {
    // 结构请求的数据
    if (response.data.code === 401) {
        localStorage.setItem('accessToken', '')
        localStorage.setItem('username', '')
        location.reload()
    }
    return response
}, (error) => {
    return Promise.reject(error)
})